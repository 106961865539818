import axios from 'axios'
import router from '../router/index'
import { Toast } from 'vant';
 
import { API_BASE_URL } from '@/common/constants'
import { getLocalToken, removeLocalToken } from '@/common/token.js'
import { HEADER_TOKEN } from '@/common/constants.js'
// import { LOGIN_ROUTE_NAME } from "@/common/constants.js"


const getToken = () => getLocalToken();

const instance = axios.create({
  baseURL: API_BASE_URL,
//   baseURL: 'http://192.168.100.43:8082/api',
  timeout: 10000
  })
// let token = '';
 
// axios.defaults.withCredentials = false;
// axios.defaults.headers.common['token'] = token;
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';//配置请求头

// http request拦截器 添加一个请求拦截器
instance.interceptors.request.use(function (config) {
//   console.log(config.headers);
    // let token = window.localStorage.getItem("token")
    // console.log(token);
    // if (token) {
    // //     //将token放到请求头发送给服务器,将tokenkey放在请求头中
    //     config.headers.Authorization = getLocalToken();
    // }
    config['headers'][HEADER_TOKEN] = getToken();
    return config;
}, function (error) {
    Toast.fail('请求超时');
    // Do something with request error
    return Promise.reject(error);
});
 
// 响应拦截器
instance.interceptors.response.use(function (response) {
//   console.log(response);
    if (response.data && response.data.code) {
        if (response.data.message === 'token失效' || response.data.message === '请求头中没有token') {
            //未登录
            Toast.fail("登录信息已失效，请重新登录");
            removeLocalToken(response.config)
            // router.push('/login');
            router.replace('/login');
        }
        if (parseInt(response.data.code) === -1) {
            Toast.fail("请求失败");
        }
    }
    return response;
}, function (error) {
    Toast.fail("服务器连接失败");
    return Promise.reject(error);
})



// function POST (id) {
//   return instance({
//     url: 'api/xkcwebApi/srClassification/querySrClassificationTree.htm',
//     method: 'post',
//     data: id
//   })
// }

// export { POST }



let base = '';
 
//通用方法
export const POST = (url, params) => {
    // const getTimestamp = new Date().getTime();
    return instance.post(url, params).then(res => res.data)
}

export const GET = (url, params) => {
    const getTimestamp = new Date().getTime();
    return instance.get(`${base}${url}?timer=${getTimestamp}`, { params: params }).then(res => res.data)
}
 
export const PUT = (url, params) => {
    return instance.put(`${base}${url}`, params).then(res => res.data)
}
 
export const DELETE = (url, params) => {
    return instance.delete(`${base}${url}`, { params: params }).then(res => res.data)
}
 
export const PATCH = (url, params) => {
    return instance.patch(`${base}${url}`, params).then(res => res.data)
}

