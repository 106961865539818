<template>
  <div class="home b50" v-show="currentLoading" v-wechat-title="$route.meta.title">
    <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh" >
    <!-- <div class="content p16">
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="44" viewBox="0 0 27 44" fill="none" class="svgIcn">
        <path id="Vector" opacity="0.5" d="M23.3859 27.7566C24.8732 26.1491 25.7844 24.0521 25.9652 21.8209C26.146 19.5898 25.5853 17.3618 24.378 15.5144C23.1707 13.667 21.3912 12.3139 19.3409 11.6844C17.2907 11.0549 15.0958 11.1878 13.1283 12.0604C11.1608 12.933 9.54164 14.4918 8.54524 16.4725C7.54884 18.4532 7.23648 20.7341 7.66108 22.9286C8.08569 25.1231 9.22115 27.0963 10.875 28.5137C12.5289 29.9311 14.5995 30.7056 16.736 30.706H16.8159H23.2215C23.2823 30.7062 23.3424 30.6929 23.3979 30.6669C23.4535 30.641 23.5031 30.603 23.5435 30.5554C23.584 30.5079 23.6144 30.4519 23.6326 30.3911C23.6509 30.3304 23.6567 30.2663 23.6495 30.203L23.3859 27.7566Z" fill="#FE6D03" fill-opacity="0.5"/>
        <path id="Vector_2" d="M23.3177 20.0913L21.068 18.6027C20.9129 18.5004 20.7345 18.4435 20.5513 18.438C20.3681 18.4324 20.1868 18.4783 20.0263 18.571C19.8658 18.6637 19.7318 18.7997 19.6384 18.965C19.545 19.1302 19.4955 19.3187 19.4951 19.5108V22.4896C19.4951 22.6819 19.5442 22.8707 19.6375 23.0362C19.7308 23.2018 19.8648 23.3381 20.0255 23.4309C20.1862 23.5236 20.3677 23.5695 20.5511 23.5637C20.7345 23.5578 20.913 23.5005 21.068 23.3977L23.3177 21.9091C23.4639 21.812 23.5844 21.6778 23.6678 21.5189C23.7513 21.36 23.795 21.1816 23.795 21.0002C23.795 20.8188 23.7513 20.6404 23.6678 20.4815C23.5844 20.3226 23.4639 20.1884 23.3177 20.0913V20.0913Z" fill="#F4F5FA"/>
        <path id="Vector_3" opacity="0.9" d="M11.4494 9C9.19985 8.99903 6.99993 9.69284 5.12446 10.9948C3.249 12.2967 1.78115 14.149 0.904319 16.3202C0.0274846 18.4914 -0.219458 20.8853 0.194344 23.2028C0.608146 25.5203 1.66434 27.6586 3.23098 29.3506L2.90366 32.3775C2.89532 32.4557 2.90282 32.5348 2.92567 32.6098C2.94852 32.6847 2.9862 32.7537 3.03624 32.8123C3.08629 32.8709 3.14758 32.9178 3.2161 32.9499C3.28463 32.9819 3.35884 32.9985 3.43388 32.9984H11.3516H11.4509C14.4553 32.9476 17.3203 31.6609 19.4277 29.416C21.5352 27.1711 22.7162 24.1478 22.716 20.9985C22.7158 17.8491 21.5344 14.826 19.4267 12.5814C17.319 10.3367 14.4538 9.05045 11.4494 9V9Z" fill="#23B8FF"/>
        <path id="Vector_4" d="M15.874 19.0386L11.0205 15.83C10.6861 15.6087 10.3012 15.4855 9.90583 15.4732C9.5105 15.4609 9.11926 15.5599 8.77288 15.76C8.42649 15.9601 8.13764 16.2538 7.93644 16.6107C7.73523 16.9676 7.62903 17.3745 7.62891 17.789V24.2124C7.62925 24.6268 7.73555 25.0336 7.93676 25.3904C8.13797 25.7472 8.42673 26.041 8.77299 26.2411C9.11926 26.4413 9.51037 26.5405 9.90564 26.5286C10.3009 26.5166 10.6859 26.3938 11.0205 26.1729L15.874 22.9612C16.188 22.7507 16.4464 22.4606 16.6254 22.1179C16.8044 21.7751 16.8982 21.3907 16.8982 20.9999C16.8982 20.6092 16.8044 20.2247 16.6254 19.882C16.4464 19.5392 16.188 19.2492 15.874 19.0386Z" fill="white"/>
      </svg>
      <span>猩课程</span>
    </div> -->
    <van-search v-model="value" placeholder="请输入课程标题搜索" class="search p16" left-icon="" disabled @click="searchPage">
      <template slot="label" class="seachico">
        <span class="icon-Group-863"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
      </template>
    </van-search>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item) in advertisementList" :key="item.id" style="height: 150px;overflow: hidden;border-radius: 10px; margin: auto;">
        <img width="100%" :src="`http://114.116.236.37:9001/${item.advertisementUrl}`" alt="" class="content-img">
      </van-swipe-item>
    </van-swipe>
    <Recommen ref="recommen"/>
    <Newcurriculum ref="newcurriculum"/>
    </van-pull-refresh>
    <Footer/>
  </div>
</template>

<script>
import { POST } from '@/common/request';
const api = {
  querySrAdvertisementInfoList:'/xkcshowApi/show/querySrAdvertisementPageList.htm'
};
import Footer from "@/components/Footer";
import Recommen from '@/components/Recommend.vue';
import Newcurriculum from '@/components/Newcurriculum.vue'
export default {
  name: 'Home',
  components: {
    Footer,
    Recommen,
    Newcurriculum
  },
  data() {
    return {
      value:'',
      advertisementList:[],
      isLoading: false,
      currentLoading: false
    };
  },
  mounted(){
    let currentUrl = localStorage.getItem('currentUrl')
    if(currentUrl){
      this.$router.push({
        path: currentUrl
      });
      localStorage.setItem('currentUrl', '')
    }else{
      this.getCurriculum()
      this.currentLoading = true
    }
  },
  methods: {
    getCurriculum(){
      POST(api.querySrAdvertisementInfoList,{advertisementStatus:0,page: 1,size: 10})
      .then((res) => {
         if(res.code === 200) {
            this.advertisementList = res.data
            setTimeout(() => {
                this.isLoading = false;
            }, 1000);
          } 
      })
      .catch(error => {
        console.log(error)
      })
    },
    onRefresh() {
      // setTimeout(() => {
        this.$refs.recommen.getCurriculum(true);
        this.$refs.newcurriculum.getCurriculum(true);
        this.getCurriculum();
        // this.isLoading = false;
      // }, 1000);
    },
    searchPage() {
    this.$router.push({name:'searchpage'})
  }
  }

}
</script>

<style lang="less" scoped>
.content {
    height: 47px;
    line-height: 47px;
    display: flex;
    justify-content: flex-start;
    height: 30px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
    .svgIcn {
      height: 47px;
      line-height: 47px;
      margin-right: 5px;
    }
  }
  .search {
      margin-top: 6px;
     .icon-Group-863 {
        display: block;
        height: 20px;
        font-size: 32px;
    }
  }
  .van-search__content {
    padding: 0;
  }
   .my-swipe {
    // margin: 11px 0 12px 0;
        margin: 11px 16px;
     .van-swipe-item {
      color: #fff;
      font-size: 20px;
      text-align: center;
    }
    /deep/.van-swipe__indicator {
      width: 10px;
      height: 2px;
      border-radius: 50px;
    }
    /deep/ .van-swipe__indicators {
      position: absolute;
      left: 82%;
    }
    // .content-img {
    //   width: 100%;
    //   height: 100%;
    //   padding: 0 8px;
    // }
   }
   
   
</style>
