import router from './router'
import store from './store'

router.beforeEach((to, from, next) => {
    // 微信公众号授权
    if (to.meta.noAuth) {
      // 路由需要授权
      if (Object.keys(store.getters.wechatUserInfo).length === 0 ) {
    //     // // 获取用户信息
        if (store.getters.openid && store.getters.accessToken) {
          // 存在openid和accessToken，已经授过权
          // 判断accessToken是否过期，过期刷新token，获取用户信息
          store.dispatch('wechatuser/getUserInfo', store.getters)
            .then((res) => {
              if(res.code === 200){
                next()
              }
              // this.$router.push({
              //   path: localStorage.getItem("currentUrl")
              // });
              // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
              // this.loading = false
            })
          // if(res){
          //   next()
          // }
        } else {
          // todo 跳转网页授权
          // 记录当前页面url
          localStorage.setItem('currentUrl', to.fullPath)
          next({ path: '/getauth' })
        }
      }else{
        // todo 已经存在用户信息，需要定期更新
        next()
      }
    } else {
      // 路由不需要授权
      next()
    }
  })