import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission' // permission control


// import axios from 'axios'
// import { POST } from './common/request';
// import { GET } from './common/request';
import VueWechatTitle from 'vue-wechat-title'
import "@/assets/style/index.less";
import 'vant/lib/index.css';
import './assets/style/font/iconfont.css'
import { Col, Row } from 'vant';
import { NavBar } from 'vant';
import { Search } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Lazyload } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Grid, GridItem } from 'vant';
import { Image as VanImage } from 'vant';
import { Tab, Tabs } from 'vant';
import { Button } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Uploader } from 'vant';
import { Field } from 'vant';
import { Loading } from 'vant';
import { Form } from 'vant';
import { Icon } from 'vant';
import { PullRefresh } from 'vant';
import { List } from 'vant';
// import { Divider } from 'vant';
import { Empty } from 'vant';
// import { Sticky } from 'vant';


Vue.use(VueWechatTitle)
Vue.use(Col);
Vue.use(Row);
Vue.use(NavBar);
Vue.use(Search);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(VanImage);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Uploader);
Vue.use(Field);
Vue.use(Loading);
Vue.use(Form);
Vue.use(Icon);
Vue.use(PullRefresh);
Vue.use(List).use(Empty);
// Vue.use(Sticky);

// Vue.config.productionTip = false
// Vue.prototype.axios = axios;
// Vue.prototype.postRequest = POST;
// Vue.prototype.getRequest = GET;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
