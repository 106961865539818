// import WechatService from '@/api/wechat'
import {GET} from '@/common/request';
import localStorageUtil from '@/common/userinfo';
import { getLocalToken, setLocalToken} from '@/common/token.js'
// import getters from '../getters'

const api = {
  // 初次授权
  wechatAuthUserInfo: '/wechat/auth/user/info',
  // 静默授权
  wechatTokenUserInfo: '/wechat/token/user/info'
  //   '/wechat/token/user/info'
};

// export default {
  const state = {
    openid: '',
    accessToken: '',
    wechatUserInfo: {},
    tokenVal: getLocalToken()
  }
    // getters: {
    //   unionId: state => {
    //     return state.unionId || localStorageUtil.get('unionId')
    //   },

  //   },
  const mutations = {
    SERVE_WECHAT_USER_INFO: (state, res) => {
      state.wechatUserInfo = res
      state.openid = res.openid
      state.accessToken = res.accessToken
      state.tokenVal = res.tokenVal
      localStorageUtil.set('openid', res.openid)
      localStorageUtil.set('accessToken', res.accessToken)
      // 保存userInfo，设置有效时间，默认30天
      localStorageUtil.set('wechatUserInfo', res, 30)
      setLocalToken(res.tokenVal)
    }
  }

  const actions = {
    // 静默授权获取用户信息
    getUserInfo({commit}, getters) {
      // getLocalToken()
      const openid = getters.openid
      const token = getters.accessToken
      if (openid && token) {
        return new Promise((resolve, reject) => {
          GET(api.wechatTokenUserInfo,{ accessToken: token, openid: openid })
            .then((res) => {
              if (res.code === 200) {
                commit('SERVE_WECHAT_USER_INFO', {
                  openid: res.data.openId,
                  accessToken: res.data.accessToken,
                  wxAvatar: res.data.wxAvatar,
                  wxNickname: res.data.wxNickname,
                  sex: res.data.sex,
                  tokenVal: res.data.token,
                  userId: res.data.userId
                })
              }
              resolve(res)
            })
            .catch(error => {
              console.log(error)
              reject(error)
            })
        })
      }
    },
    // 初次授权获取用户信息
    getAuthUserInfo({commit}, wechatVal) {
      const { code, state } = wechatVal
      if (code && state) {
        return new Promise((resolve, reject) => {
          // console.log(code,state)
          GET(api.wechatAuthUserInfo,{ code: code, state: state })
            .then((res) => {
              if (res.code === 200) {
                commit('SERVE_WECHAT_USER_INFO', {
                  openid: res.data.openId,
                  accessToken: res.data.accessToken,
                  wxAvatar: res.data.wxAvatar,
                  wxNickname: res.data.wxNickname,
                  sex: res.data.sex,
                  tokenVal: res.data.token
                })
                resolve(res)
              }
            })
            .catch(error => {
              console.log(error)
              reject(error)
            })
          // login({ username: username.trim(), password: password }).then(response => {
          //   const { data } = response
          //   commit('SET_TOKEN', data.token)
          //   setToken(data.token)
          //   resolve()
          // }).catch(error => {
          //   reject(error)
          // })
        })
        
        // GET(api.wechatAuthUserInfo,{ code: code, state: state })
        //   .then((res) => {
  
        //     if (res.code === 200) {
        //       commit('SERVE_WECHAT_USER_INFO', {
        //         openid: res.data.openId,
        //         accessToken: res.data.accessToken,
        //         wxAvatar: res.data.wxAvatar,
        //         wxNickname: res.data.wxNickname,
        //         sex: res.data.sex,
        //         tokenVal: res.data.token
        //       })
        //       return true
        //       // this.advertisementList = res.data
        //     }
        //   })
        //   .catch(error => {
        //     console.log(error)
        //     return false
        //   })

        // const res = await WechatService.getAuthUserInfo(code, state)
        // if (res.ok()) {
        //   commit('SERVE_WECHAT_USER_INFO', res.data)
        // }
      }
    }
  }
// }

export default {
  namespaced: true,
  state,
  mutations,
  actions
}