import localStorageUtil from '@/common/userinfo'
const getters = {
  token: state => state.user.token,
  openid: state => {
    return state.wechatuser.openid || localStorageUtil.get('openid')
  },
  accessToken: state => {
    return state.wechatuser.accessToken || localStorageUtil.get('accessToken')
  },
  wechatUserInfo: state => {
    return state.wechatuser.wechatUserInfo || localStorageUtil.get('wechatUserInfo')
  }
}
export default getters