import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "猩学堂",
      // keepAlive: true
    }
  },
  {
    path: "/home",
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: "首页",
      // keepAlive: true
    }
  },
  {
    path: "/curriculum",
    component: () => import('../views/Curriculum.vue'),
    meta: {
      title: "所有课程",
      // keepAlive: true
    },
  },
  {
    path: "/accountNumber",
    component: () => import('../views/AccountNumber.vue'),
    meta: {
      title: "我的信息",
      noAuth: true
      // keepAlive: true
    }
  },
  {
    path: "/details/:id",
    name: 'details',
    component: () => import('@/components/Details.vue'),
    meta: {
      title: "课程详情",
      // keepAlive: true
    }
  },
  {
    path: '/videodetils',
    name: 'videodetils',
    component: () => import('@/components/Videodetils.vue'),
    meta: {
      title: "课程视频",
      noAuth: true
      // keepAlive: true,

    }
  },
  {
    path: "/personalInfo",
    name: 'personalInfo',
    component: () => import('@/components/PersonalInfo.vue'),
    meta: {
      title: "个人信息",
      // keepAlive: true
    }
  },
  {
    path: "/login",
    name: 'login',
    component: () => import("@/views/Login.vue"),
    meta: {
      title: "登陆",
      // keepAlive: true
    }
  },
  {
    path: "/changetel",
    name: 'changetel',
    component: () => import("@/components/ChangeTel.vue"),
    meta: {
      title: "修改手机号",
      // keepAlive: true
    }
  },
  {
    path: "/changewechat",
    name: 'changewechat',
    component: () => import("@/components/ChangeWechat.vue"),
    meta: {
      title: "修改微信号",
      // keepAlive: true
    }
  },
  {
    path: "/changepassword",
    name: 'changepassword',
    component: () => import("@/components/ChangePassword.vue"),
    meta: {
      title: "修改密码",
      // keepAlive: true
    }
  },
  {
    path: "/searchpage",
    name: 'searchpage',
    component: () => import("@/components/SearchPage.vue"),
    meta: {
      title: "搜索课程",
    }
  },
  {
    path: '/getauth',
    name: 'getauth',
    component: () => import("@/components/GetAuth.vue"),
    meta: {
      title: "登录"
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router