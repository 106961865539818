<template>
   <van-tabbar v-model="active" route>
      <van-tabbar-item to="/">
      首页
      <template #icon="props">
        <span :class="props.active ? 'nav-icon' : ''  " class="icon--1"></span>
      </template>
      </van-tabbar-item>
      <van-tabbar-item to="/curriculum">
      课程
      <template #icon="props">
        <span :class="props.active ? 'nav-icon' : ''  " class="icomoon icon--24"><span class="path1"></span><span class="path2"></span><span class="path3"></span> </span>
      </template>               
      </van-tabbar-item>
      <van-tabbar-item to="/accountNumber">
      账号
      <template #icon="props">
        <span :class="props.active ? 'nav-icon' : ''  " class="icon-untitled"></span>
      </template>
      </van-tabbar-item>
    </van-tabbar>
</template>

<script>
export default {
    data() {
        return {
            active:1,
        }
    }
}
</script>

<style lang="less" scoped>
.nav-icon:before{
    color: #23b8ff;
  }

  .nav-icon .path2:before{
    color: #23b8ff;
  }
</style>