import dayjs from 'dayjs'
export default {

  get(key, defaultValue) {
    return this.parse(key, defaultValue)
  },

  set(key, obj, expires) {
    if (expires) {
      const tmpTime = dayjs().add(expires, 'days').format('YYYY-MM-DD')
      const handleObj = { expires: tmpTime, value: obj }
      localStorage.setItem(key, JSON.stringify(handleObj))
    } else {
      if ((obj != null && (typeof obj == 'object' || typeof obj == 'function'))) {
        localStorage.setItem(key, JSON.stringify(obj))
      } else {
        localStorage.setItem(key, obj)
      }
    }
  },

  remove(key) {
    localStorage.removeItem(key)
  },


  parse(key, defaultValue) {
    let value = localStorage.getItem(key)
    if ((value != null && (typeof value == 'object' || typeof value == 'function'))) {
      const valueObj = JSON.parse(value)
      if (valueObj.expires) {
        // 有过期时间，判断是否过期：在现在时间之前，过期
        if (dayjs(valueObj.expires).isBefore(dayjs(), 'day')) {
          // 删除
          this.remove(key)
          // 直接返回
          return null
        }
        return valueObj.value
      }
      // 没有过期时间直接返回对象
      return valueObj
    }
    // 不是对象，返回值
    return value || defaultValue
  }
}
