// import { POST } from '@/common/request';
import {
  getLocalToken,
  setLocalToken,
  removeLocalToken
} from '@/common/token.js'
// import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getLocalToken(),
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  }
}

const actions = {
  // user login
  login({
    commit
  }) {
    // const { username, password } = userInfo
    return new Promise((resolve) => {
      //   login({ username: username.trim(), password: password }).then(response => {
      // mypost('/xkcwebApi/srMoveUser/addAndupdateSrMoveUser.htm', userInfo).then(response => {
      //   const { data } = response
      //   commit('SET_TOKEN', data.token)
      //   setLocalToken(data.token)
      //   resolve()
      // }).catch(error => {
      //   reject(error)
      // })
      commit('SET_TOKEN', '1')
      setLocalToken('1')
      resolve()
    })
  },

  // remove token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      removeLocalToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}